/*--------------------------------

farpoint Web Font
Generated using nucleoapp.com

-------------------------------- */
@font-face {
  font-family: 'farpoint';
  src: url('../fonts/farpoint.eot');
  src: url('../fonts/farpoint.eot') format('embedded-opentype'), url('../fonts/farpoint.woff2') format('woff2'), url('../fonts/farpoint.woff') format('woff'), url('../fonts/farpoint.ttf') format('truetype'), url('../fonts/farpoint.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*------------------------
	base class definition
-------------------------*/
.farpoint-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'farpoint';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*------------------------
  change icon size
-------------------------*/
/* relative units */
.farpoint-icon-sm {
  font-size: 0.8em;
}
.farpoint-icon-lg {
  font-size: 1.2em;
}
/* absolute units */
.farpoint-icon-16 {
  font-size: 16px;
}
.farpoint-icon-32 {
  font-size: 32px;
}
/*----------------------------------
  add a square/circle background
-----------------------------------*/
.farpoint-icon-bg-square,
.farpoint-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}
.farpoint-icon-bg-circle {
  border-radius: 50%;
}
/*------------------------------------
  use icons as list item markers
-------------------------------------*/
.farpoint-icon-ul {
  padding-left: 0;
  list-style-type: none;
}
.farpoint-icon-ul > li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}
.farpoint-icon-ul > li > .farpoint-icon {
  margin-right: 0.4em;
  line-height: inherit;
}
/*------------------------
  spinning icons
-------------------------*/
.farpoint-icon-is-spinning {
  -webkit-animation: farpoint-icon-spin 2s infinite linear;
  -moz-animation: farpoint-icon-spin 2s infinite linear;
  animation: farpoint-icon-spin 2s infinite linear;
}
@-webkit-keyframes farpoint-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes farpoint-icon-spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@keyframes farpoint-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/*------------------------
  rotated/flipped icons
-------------------------*/
.farpoint-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}
.farpoint-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.farpoint-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}
.farpoint-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  -moz-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.farpoint-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  -moz-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  transform: scale(1, -1);
}
/*------------------------
	icons
-------------------------*/

.farpoint-icon-spinner::before {
  content: "\ea02";
}

.farpoint-icon-verified::before {
  content: "\ea03";
}

.farpoint-icon-check-full::before {
  content: "\ea04";
}

.farpoint-icon-privacy::before {
  content: "\ea0a";
}

.farpoint-icon-logout::before {
  content: "\ea12";
}

.farpoint-icon-question::before {
  content: "\ea13";
}

.farpoint-icon-cogwheel::before {
  content: "\ea14";
}

.farpoint-icon-upload-file::before {
  content: "\ea15";
}

.farpoint-icon-bulb::before {
  content: "\ea16";
}

.farpoint-icon-nav-up::before {
  content: "\ea17";
}

.farpoint-icon-nav-down::before {
  content: "\ea18";
}

.farpoint-icon-a-edit::before {
  content: "\ea19";
}

.farpoint-icon-key::before {
  content: "\ea1a";
}

.farpoint-icon-bill::before {
  content: "\ea1b";
}

.farpoint-icon-credit-card::before {
  content: "\ea1c";
}

.farpoint-icon-users-wm::before {
  content: "\ea1d";
}

.farpoint-icon-add-user::before {
  content: "\ea1e";
}

.farpoint-icon-more::before {
  content: "\ea1f";
}

.farpoint-icon-support::before {
  content: "\ea21";
}

.farpoint-icon-folder::before {
  content: "\ea22";
}

.farpoint-icon-drag::before {
  content: "\ea23";
}

.farpoint-icon-nav-right::before {
  content: "\ea27";
}

.farpoint-icon-check::before {
  content: "\ea28";
}

.farpoint-icon-info::before {
  content: "\ea29";
}

.farpoint-icon-warning::before {
  content: "\ea2a";
}

.farpoint-icon-hide::before {
  content: "\ea2b";
}

.farpoint-icon-unhide::before {
  content: "\ea2c";
}

.farpoint-icon-c-check-s::before {
  content: "\ea2d";
}

.farpoint-icon-t-warning-s::before {
  content: "\ea2e";
}

.farpoint-icon-c-warning-s::before {
  content: "\ea2f";
}

.farpoint-icon-c-question-s::before {
  content: "\ea30";
}

.farpoint-icon-c-info-s::before {
  content: "\ea31";
}

.farpoint-icon-c-question-full::before {
  content: "\ea32";
}

.farpoint-icon-grid-interface::before {
  content: "\ea33";
}

.farpoint-icon-shape-star::before {
  content: "\ea34";
}

.farpoint-icon-buy-order::before {
  content: "\ea35";
}

.farpoint-icon-sell-order::before {
  content: "\ea37";
}

.farpoint-icon-email::before {
  content: "\ea38";
}

.farpoint-icon-bookmark-delete::before {
  content: "\ea39";
}

.farpoint-icon-bookmark-add::before {
  content: "\ea3a";
}

.farpoint-icon-bookmark::before {
  content: "\ea3b";
}

.farpoint-icon-bookmark-2::before {
  content: "\ea3c";
}

.farpoint-icon-bookmark-add-2::before {
  content: "\ea3d";
}

.farpoint-icon-bookmark-delete-2::before {
  content: "\ea3e";
}

.farpoint-icon-c-add::before {
  content: "\ea3f";
}

