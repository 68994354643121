@import "./animate.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #f8f8f8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace !important;
}

#root {
  height: 100%;
}

.sans {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.btn {
  @apply py-10;
}

.space-jam > * + * {
  @apply ml-4;
}

/* 

  Animation utilities for animate.css

*/

.delay-halfs {
  animation-delay: 0.5s;
}

.delay-quarters {
  animation-delay: 0.25s;
}

.fastest {
  animation-duration: 250ms;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s linear infinite;
}

.StripeElement--focus {
  outline: none;
  border: 2px solid #3d79ff;
}

/* Generic Styles */
label {
  cursor: pointer;
}

/* Override  */
.trustime {
  font-family: "trustime" !important;
}

.ui.dimmer {
}

[tabIndex="0"]:focus {
  outline: none;
}

/* Toast */
.Toastify__toast {
  box-shadow: none !important;
  margin-bottom: 0 !important;
  padding-bottom: 20px !important;
  padding-top: 4px !important;
}

.Toastify__toast--default {
  background: none !important;
}

@media screen and (min-width: 480px) {
  .Toastify__toast-container {
    width: 480px !important;
  }
}

/* Common styles */
.title-small {
  @apply uppercase;
  @apply text-gray-500;
  @apply font-bold;
  @apply text-xs;
  @apply tracking-wider;
}
.title-md {
  @apply text-lg;
  @apply leading-6;
  @apply font-medium;
  @apply text-gray-900;
}
.title-lg {
  @apply text-xl;
  @apply leading-6;
  @apply font-medium;
  @apply text-gray-900;
}
.title-xl {
  @apply text-2xl;
  @apply leading-6;
  @apply font-medium;
  @apply text-gray-900;
}
